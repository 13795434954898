export default {
  primary: {
    backgroundColor: 'transparent',
    padding: '1rem 2rem',
    color: 'white',
    borderBottom: 'solid 2px',
    transition: 'all ease-in-out 0.7s',
    backgroundSize: '204% 100%',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(to right,rgba(0,0,0,0) 50%,#579c3b 50%)',
    borderColor: 'tertiary',
    fontFamily: 'heading',
    fontWeight: 'normal',
    ':hover': {
      backgroundColor: 'transparent',
      backgroundPosition: '-98% 0%',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'dark',
    borderBottom: 'solid 2px',
    transition: 'all ease-in-out 0.7s',
    backgroundSize: '204% 100%',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(to right,rgba(0,0,0,0) 50%,#d01f31 50%)',
    borderColor: 'primary',
    padding: '0.5rem 1.5rem',
    fontFamily: 'heading',
    fontWeight: 'normal',
    ':hover': {
      backgroundColor: 'transparent',
      backgroundPosition: '-98% 0%',
      color: 'light'
    }
  }
}

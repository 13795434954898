export default {
  heading: 'Open Sans, serif',
  subheading: 'Cinzel, sans-serif',
  body: 'Open Sans, serif',
  monospace: 'Menlo, monospace',
  display: 'Cinzel, serif',
  googleFonts: [
    // 'Righteous',
    // 'Rubik:100,200,300,400,500,600,700,800,900',
    // 'Monofett',
    // 'Lobster',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Cinzel:400,500,600,700,800,900'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}

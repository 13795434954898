export default {
  text: 'black',
  primary: '#d01f31',
  secondary: '#292929',
  tertiary: '#579c3b',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
